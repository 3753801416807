<template>
  <div class="innerPage" style="">
    <!-- <div class="trans1"> -->
    <div class="title flexCenter"
      style="width: 100%;height: 40px; border-top: 1px solid #000; border-left: 1px solid #000;border-right: 1px solid #000;">
      {{ schoolname }}实习指导教师下企业巡视记录</div>
    <div class="form" style="height: calc( 21cm - 120px );">
      <el-row class="borderR">
        <el-col :span="2" class="flexCenter cell cell50">所在系部</el-col>
        <el-col :span="3" class="flexCenter cell cell50" style="line-height: unset;padding: 0 3px;">
          {{ info.dept_name || '-' }}
        </el-col>
        <el-col :span="2" class="flexCenter cell cell50">所在班级</el-col>
        <el-col :span="3" class="flexCenter cell cell50" style="line-height: unset;padding: 0 3px;">
          {{ (info.stuObj && info.stuObj.class_name) ? info.stuObj.class_name : '-' }}
        </el-col>
        <el-col :span="2" class="flexCenter cell cell50">指导老师</el-col>
        <el-col :span="2" class="flexCenter cell cell50">{{ info.username }}</el-col>
        <el-col :span="3" class="flexCenter cell cell50">系实习就业干事</el-col>
        <el-col :span="2" class="flexCenter cell cell50">{{ info.teacher || '-' }}</el-col>
        <el-col :span="2" class="flexCenter cell cell50">巡访月份</el-col>
        <el-col :span="3" class="flexCenter cell cell50">{{ info.visit_YM }}</el-col>
      </el-row>
      <el-row class="borderR">
        <el-col :span="2" class="flexCenter cell cell50">巡访时间</el-col>
        <el-col :span="8" class="flexCenter cell cell50">{{ info.visit_date }}</el-col>
        <el-col :span="2" class="flexCenter cell cell50">企业名称</el-col>
        <el-col :span="12" class="flexCenter cell cell50" style="line-height: unset;padding: 0 3px;">{{ info.enter_name
          }}</el-col>
      </el-row>
      <el-row class="borderR">
        <el-col :span="2" class="flexCenter cell cell50">企业地址</el-col>
        <el-col :span="22" class="flexCenter cell cell50" style="line-height: unset;padding: 0 3px;">{{
        info.visit_address }}</el-col>
      </el-row>
      <el-row class="borderR flexStretch">
        <el-col :span="2" class="flexCenter cell cell50">实习学生</el-col>
        <el-col :span="14" class="flexCenter cell cell50" style="line-height: unset;padding: 0 3px;">
          <div :id="'divCntnrB_' + idx" class="flexStartUp"
            style="width:100%;height: 50px;overflow: hidden;box-sizing: border-box;position: relative;">
            <div :id="'resizeDivB_' + idx" class="flexStart" style="width: 100%;min-height:100%;word-break: break-word">
              {{ info.stunames || '-' }}
            </div>
          </div>
        </el-col>
        <el-col :span="2" class="flexCenter cell cell50">实习岗位</el-col>
        <el-col :span="2" class="flexCenter cell cell50" style="line-height: unset;padding: 0 3px;">{{ info.job
          }}</el-col>
        <el-col :span="2" class="flexCenter cell cell50">实习情况</el-col>
        <el-col :span="2" class="flexCenter cell cell50" style="line-height: unset;padding: 0 3px;">{{ info.situation
          }}</el-col>
      </el-row>
      <el-row class="borderR flexStretch" style="height: calc( 100% - 400px );">
        <el-col :span="2" class="flexCenter cell">巡访小结</el-col>
        <el-col :span="22" class="cell" style="padding: 10px;">


          <div :id="'divCntnrA_' + idx"
            style="width:100%;height: 100%;overflow: hidden;box-sizing: border-box;position: relative;">
            <div :id="'resizeDivA_' + idx" style="width: 100%;word-break: break-word">
              {{ info.visit_summary || '-' }}
            </div>
          </div>


        </el-col>
      </el-row>
      <el-row :class="xunfangsp ? 'borderR flexStretch' : 'borderR borderB flexStretch'">
        <el-col :span="2" class="flexCenter cell">巡访照片</el-col>
        <el-col :span="22" class="flexStart cell"
          :style="xunfangsp ? 'padding: 10px;height: 150px;overflow: hidden;' : 'padding: 10px;height: 200px;overflow: hidden;'">
          <div class="" style="width: 24%;height: 100%;margin-right: 1%;" v-for="(item, index) in info.fieldname">
            <img :src="item.fileUrl || item.src" alt="" style="max-width: 100%;max-height: 100%;">
          </div>
        </el-col>
      </el-row>
      <el-row v-if="xunfangsp" class="borderR borderB flexStretch">
        <el-col :span="2" class="flexCenter cell cell50"
          style="line-height: unset;text-align: center;">系部审核<br />签字</el-col>
        <el-col :span="22" class="flexBetween cell cell50" style="line-height: unset;padding: 0px 10px;height:50px;">
          <div :id="'divCntnrC_' + idx" v-if="info.audit_remark"
            style="width:100%;height: 100%;overflow: hidden;box-sizing: border-box;position: relative;">
            <div :id="'resizeDivC_' + idx" style="width: 100%;word-break: break-word">
              {{ info.audit_remark || '-' }}
            </div>
          </div>
          <div v-if="info.sign_url" class="flexStart" style="width: 150px;height: 48px;flex-shrink:0;">
            <el-image :src="info.sign_url" alt="" style="width: 100%;height: 100%;" fit="contain"></el-image>
          </div>
        </el-col>
      </el-row>
    </div>
    <!-- </div> -->
  </div>
</template>

<script>

export default {
  name: 'roll',
  data() {
    return {
      dataover: false,
      userid: ''
    }
  },
  props: {
    info: {
      type: Object,
      default: {}
    },
    idx: {
      type: Number,
      default: 1
    },
    xunfangsp: {
      type: Number,
      default: 1
    },
    schoolname: {
      type: String,
      default: ''
    },
  },
  mounted() {
    let _this = this
    if (_this.info.username) {
      _this.$nextTick(() => {
        _this.resizeA()
        _this.resizeB()
      })
    }
    if (_this.info.audit_remark) {
      _this.$nextTick(() => {
        _this.resizeC()
      })
    }

  },
  methods: {
    resizeA() {
      let divHeight = document.getElementById(`divCntnrA_${this.idx}`).offsetHeight
      let textHeight = document.getElementById(`resizeDivA_${this.idx}`).offsetHeight
      // console.log('a', divHeight, textHeight)
      let i = 1
      while (textHeight > divHeight && i < 5) {
        document.getElementById(`resizeDivA_${this.idx}`).style.fontSize = `${14 - i}px`
        textHeight = document.getElementById(`resizeDivA_${this.idx}`).offsetHeight
        i++
      }
    },
    resizeB() {
      let divHeight = document.getElementById(`divCntnrB_${this.idx}`).offsetHeight
      let textHeight = document.getElementById(`resizeDivB_${this.idx}`).offsetHeight
      // console.log('b', this.idx, divHeight, textHeight)
      let i = 1
      while ((textHeight > divHeight) && i < 5) {
        document.getElementById(`resizeDivB_${this.idx}`).style.fontSize = `${14 - i}px`
        textHeight = document.getElementById(`resizeDivB_${this.idx}`).offsetHeight
        i++
      }
    },

    resizeC() {
      let divHeight = document.getElementById(`divCntnrC_${this.idx}`).offsetHeight
      let textHeight = document.getElementById(`resizeDivC_${this.idx}`).offsetHeight
      console.log('C', this.idx, divHeight, textHeight)
      let i = 1
      while ((textHeight > divHeight) && i < 5) {
        document.getElementById(`resizeDivC_${this.idx}`).style.fontSize = `${14 - i}px`
        textHeight = document.getElementById(`resizeDivC_${this.idx}`).offsetHeight
        i++
      }
    },

  }
}
</script>

<style lang="less" type="text/less">
.stuRolls .ant-col {
  display: flex;
}
</style>
<style lang="less" type="text/less" scoped>
.innerPage {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 40px;
  overflow: hidden;
  font-size: 20px;
  box-sizing: border-box;

  .borderR {
    border-right: 1px solid #333;
  }

  .borderB {
    border-bottom: 1px solid #333;
  }

  .cell {
    border-top: 1px solid #333;
    border-left: 1px solid #333;
    word-break: break-all;

    min-height: 30px;
    font-size: 14px;
    white-space: normal;
  }

  .cell50 {
    min-height: 50px;
    line-height: 50px;
    height: 50px;
    font-size: 14px;
  }

  .title {
    font-size: 20px;
    line-height: 50px;
  }

  .form {
    width: 100%;
    height: calc(100% - 50px);
    padding: 0;
    border: 0;

    div {
      font-size: 14px;
      color: #323232;
      box-sizing: border-box;
    }

  }
}

.trans1 {
  width: 100%;
  height: 100%;
  padding: 40px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  /*transform: rotate(-90deg);*/

}
</style>
